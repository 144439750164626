import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useRef, useState } from 'react';
import { FaExpand, FaHome, FaInfoCircle, FaTwitter } from 'react-icons/fa';
import './App.css';

const App = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [data, setData] = useState([]);
  const [scale, setScale] = useState(1);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [selectedCategory, setSelectedCategory] = useState({ enterprise: true, individual: true });
  const canvasRef = useRef(null);
  const imgRef = useRef(null);
  const [initialScale, setInitialScale] = useState(1);

  useEffect(() => {
    fetch('./booths.json')
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setResults(data);
        drawCanvas(data.filter(booth => booth.head), true);
      });
  }, []);

  useEffect(() => {
    const img = new Image();
    img.src = './20240921.png';
    img.onload = () => {
      imgRef.current = img;
      const canvas = canvasRef.current;
      const imgWidth = img.width;
      const imgHeight = img.height;
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const scaleX = canvasWidth / imgWidth;
      const scaleY = canvasHeight / imgHeight;
      const initialScaleValue = Math.min(scaleX, scaleY);
      setInitialScale(initialScaleValue);
      setScale(initialScaleValue);
      drawCanvas(data.filter(booth => booth.head), true);
    };
  }, [data]);

  useEffect(() => {
    if (query) {
      drawCanvas(results, true);
    } else {
      drawCanvas(data.filter(booth => booth.head), true);
    }
  }, [scale, translate, query, results, data]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const handleWheel = (event) => {
      if (canvas.contains(event.target)) {
        event.preventDefault();
        const scaleChange = event.deltaY > 0 ? -0.1 : 0.1;
        const newScale = Math.min(Math.max(initialScale, scale + scaleChange), 3);
        setScale(newScale);
      }
    };
    canvas.addEventListener('wheel', handleWheel);
    return () => {
      canvas.removeEventListener('wheel', handleWheel);
    };
  }, [scale, initialScale]);

  const handleSearch = (event) => {
    event.preventDefault();
    const filteredResults = data.filter((booth) => {
      const normalizedQuery = query.replace(/\s+/g, '').toLowerCase();
      const normalizedBoothNumber = (booth.booth_number || '').replace(/\s+/g, '').toLowerCase();
      const normalizedBoothName = (booth.booth_name || '').replace(/\s+/g, '').toLowerCase();
      const normalizedOperatorName = (booth.operator_name || '').replace(/\s+/g, '').toLowerCase();
      const normalizedItemsSold = (booth.items_sold || '').replace(/\s+/g, '').toLowerCase();
      const normalizedAllTag = (booth.all_tag || '').replace(/\s+/g, '').toLowerCase();
      const categoryMatch = (selectedCategory.enterprise && booth.category === '기업') || 
                            (selectedCategory.individual && booth.category === '개인') ||
                            (selectedCategory.enterprise && selectedCategory.individual);
  
      return (
        (normalizedBoothNumber.includes(normalizedQuery) ||
        normalizedBoothName.includes(normalizedQuery) ||
        normalizedOperatorName.includes(normalizedQuery) ||
        normalizedItemsSold.includes(normalizedQuery) ||
        normalizedAllTag.includes(normalizedQuery)) &&
        categoryMatch
      );
    });
    setResults(filteredResults);
  };

  const drawCanvas = (resultsToDraw = [], drawPins = true) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (imgRef.current) {
      const imgWidth = imgRef.current.width * scale;
      const imgHeight = imgRef.current.height * scale;
      const xOffset = (canvas.width - imgWidth) / 2;
      const yOffset = (canvas.height - imgHeight) / 2;
      ctx.drawImage(imgRef.current, translate.x + xOffset, translate.y + yOffset, imgWidth, imgHeight);

      if (drawPins) {
        resultsToDraw.forEach((booth) => {
          const xRatio = imgWidth / 2569;
          const yRatio = imgHeight / 2866;
          const xPos = booth.coordinates.x * xRatio + translate.x + xOffset;
          const yPos = booth.coordinates.y * yRatio + translate.y + yOffset;
          drawPin(ctx, xPos, yPos, booth.booth_number, booth.head);
        });
      }
    }
  };

  const drawPin = (ctx, x, y, text, isHead) => {
    const pinWidth = 50;
    const pinHeight = 30;
    const pinColor = isHead ? '#FF0000' : '#4285F4';
    const pinTextColor = '#FFFFFF';
    const cornerRadius = 5;

    ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
    ctx.shadowBlur = 4;
    ctx.shadowOffsetX = 2;
    ctx.shadowOffsetY = 2;

    ctx.fillStyle = pinColor;
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x - 10, y - 20);
    ctx.lineTo(x - pinWidth / 2, y - 20);
    ctx.arcTo(x - pinWidth / 2, y - 20 - pinHeight, x + pinWidth / 2, y - 20 - pinHeight, cornerRadius);
    ctx.arcTo(x + pinWidth / 2, y - 20 - pinHeight, x + pinWidth / 2, y - 20, cornerRadius);
    ctx.lineTo(x + pinWidth / 2, y - 20);
    ctx.lineTo(x + 10, y - 20);
    ctx.closePath();
    ctx.fill();

    ctx.fillStyle = pinTextColor;
    ctx.font = 'bold 14px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(text, x, y - 35);

    ctx.shadowColor = 'transparent';
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
  };

  const handleDragStart = (event) => {
    setDragging(true);
    setDragStart({ x: event.clientX - translate.x, y: event.clientY - translate.y });
  };

  const handleDrag = (event) => {
    if (dragging) {
      setTranslate({ x: event.clientX - dragStart.x, y: event.clientY - dragStart.y });
    }
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleTouchStart = (event) => {
    if (event.touches.length === 1) {
      setDragging(true);
      setDragStart({
        x: event.touches[0].clientX - translate.x,
        y: event.touches[0].clientY - translate.y,
      });
    } else if (event.touches.length === 2) {
      const distance = Math.hypot(
        event.touches[0].clientX - event.touches[1].clientX,
        event.touches[0].clientY - event.touches[1].clientY
      );
      imgRef.current.initialDistance = distance;
    }
  };

  const handleTouchMove = (event) => {
    if (dragging && event.touches.length === 1) {
      setTranslate({
        x: event.touches[0].clientX - dragStart.x,
        y: event.touches[0].clientY - dragStart.y,
      });
    } else if (event.touches.length === 2) {
      const distance = Math.hypot(
        event.touches[0].clientX - event.touches[1].clientX,
        event.touches[0].clientY - event.touches[1].clientY
      );
      const scaleChange = distance / imgRef.current.initialDistance;
      setScale((prevScale) => Math.min(Math.max(initialScale, prevScale * scaleChange), 3));
      imgRef.current.initialDistance = distance;
    }
  };

  const handleTouchEnd = () => {
    setDragging(false);
    imgRef.current.initialDistance = null;
  };

  const handleResetView = () => {
    setScale(initialScale);
    setTranslate({ x: 0, y: 0 });
  };

  const handleFullScreen = () => {
    const canvas = canvasRef.current;
    if (canvas.requestFullscreen) {
      canvas.requestFullscreen();
    } else if (canvas.mozRequestFullScreen) { /* Firefox */
      canvas.mozRequestFullScreen();
    } else if (canvas.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      canvas.webkitRequestFullscreen();
    } else if (canvas.msRequestFullscreen) { /* IE/Edge */
      canvas.msRequestFullscreen();
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('msfullscreenchange', handleFullscreenChange);
  };

  const handleFullscreenChange = () => {
    const canvas = canvasRef.current;
    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
      const imgWidth = imgRef.current.width;
      const imgHeight = imgRef.current.height;
      const scaleX = window.innerWidth / imgWidth;
      const scaleY = window.innerHeight / imgHeight;
      const newScale = Math.min(scaleX, scaleY);
      setScale(newScale);
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    } else {
      canvas.width = 800;
      canvas.height = 600;
      setScale(initialScale);
    }
    drawCanvas(data.filter(booth => booth.head), true);
  };

  const handleCategoryChange = (event) => {
    const { name, checked } = event.target;
    setSelectedCategory((prevSelectedCategory) => ({
      ...prevSelectedCategory,
      [name]: checked,
    }));
  };

  const renderItemsSold = (items) => {
    if (!items) return null;

    // Function to split text into parts based on links
    const splitTextWithLinks = (text) => {
        const parts = [];
        const regex = /\[(.*?)\]\((.*?)\)/g;
        let lastIndex = 0;
        let match;
        
        while ((match = regex.exec(text)) !== null) {
            if (match.index > lastIndex) {
                parts.push(text.slice(lastIndex, match.index));
            }
            parts.push({ text: match[1], url: match[2] });
            lastIndex = regex.lastIndex;
        }
        
        if (lastIndex < text.length) {
            parts.push(text.slice(lastIndex));
        }
        
        return parts;
    };

    return (
      <ul style={{ listStyleType: 'disc', paddingLeft: '0px' }}>
        {items.split('#').map((item, index) => {
          const parts = splitTextWithLinks(item.trim());

          return (
            <li key={index}>
              {parts.map((part, partIndex) => {
                if (typeof part === 'string') {
                  return part;
                } else {
                  return (
                    <a
                      key={partIndex}
                      href={part.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', borderBottom: '1px dashed' }}
                    >
                      {part.text}
                    </a>
                  );
                }
              })}
            </li>
          );
        })}
      </ul>
    );
};

const renderOperator = (operator) => {
  if(!operator) return null;
  const splitTextWithLinks = (text) => {
    const parts = [];
    const regex = /\[(.*?)\]\((.*?)\)/g;
    let lastIndex = 0;
    let match;
    
    while ((match = regex.exec(text)) !== null) {
        if (match.index > lastIndex) {
            parts.push(text.slice(lastIndex, match.index));
        }
        parts.push({ text: match[1], url: match[2] });
        lastIndex = regex.lastIndex;
    }
    
    if (lastIndex < text.length) {
        parts.push(text.slice(lastIndex));
    }
    
    return parts;
  };

  const parts = splitTextWithLinks(operator);

  return (
    <em>
      {parts.map((part, index) => {
        if (typeof part === 'string') {
          return part;
      } else {
        return (
          <a
            key = {index}
            href = {part.url}
            target = "_blank"
            rel = "noopener noreferrer"
            style = {{ textDecoration: 'none', borderBottom: '1px dashed' }}
          >
            {part.text}
          </a>
        );
      }
      })}
    </em>
  )

};

  return (
    <div className="container">
      <div className="row mt-5">
        <div
          className="col text-center position-relative map-container"
          onMouseDown={handleDragStart}
          onMouseMove={handleDrag}
          onMouseUp={handleDragEnd}
          onMouseLeave={handleDragEnd}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onTouchCancel={handleTouchEnd}
        >
          <canvas ref={canvasRef} width={800} height={600}></canvas>
          <div className="canvas-buttons">
            <button className="canvas-button" onClick={handleResetView}>
              <FaHome />
            </button>
            <button className="canvas-button" onClick={handleFullScreen}>
              <FaExpand />
            </button>
          </div>
        </div>
        <div className="search-container">
          <form className="mt-3 justify-content-center flex-wrap mx-auto" onSubmit={handleSearch}>
            <div className="mb-2 align-items-center" style={{ textAlign: 'center' }}>
              <div className="form-check form-check-inline mr-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="enterprise"
                  name="enterprise"
                  checked={selectedCategory.enterprise}
                  onChange={handleCategoryChange}
                />
                <label className="form-check-label" htmlFor="enterprise">기업</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="individual"
                  name="individual"
                  checked={selectedCategory.individual}
                  onChange={handleCategoryChange}
                />
                <label className="form-check-label" htmlFor="individual">개인</label>
              </div>
            </div>
            
            <div className="mb-2 align-items-center d-flex">
              <input
                type="text"
                className="form-control mr-2"
                placeholder="Search"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                style={{ flex: 'auto', marginLeft: '8%'}}
              />
              <button
                type="submit"
                className="btn btn-primary"
                style={{ whiteSpace: 'nowrap', flex: 'initial', marginRight: '8%'}}
                disabled={!selectedCategory.enterprise && !selectedCategory.individual}
              >
                검색
              </button>
            </div>
          </form>
          <div className="mt-3">
            {results.map((booth, index) => (
              <div key={index} className="card mb-2">
                <div className="card-body d-flex justify-content-between align-items-center">
                  <div>
                    <h5 className="card-title">
                      <b>{booth.booth_number}</b> {booth.booth_name}
                      {booth.category && (
                        <span className={`badge ${booth.category === '기업' ? 'badge-success' : 'badge-danger'} ml-2`}>
                          {booth.category}
                        </span>
                      )}
                    </h5>
                    <p className="card-text">{renderOperator(booth.operator_name)}</p>
                    <ul className="card-text">
                      {renderItemsSold(booth.items_sold)}
                    </ul>
                  </div>
                  <div className="icon-links">
                    {booth.info_url && (
                      <a href={booth.info_url} target="_blank" rel="noopener noreferrer" className="icon-link">
                        <FaInfoCircle />
                      </a>
                    )}
                    {booth.twt_id && (
                      <a href={`https://twitter.com/${booth.twt_id}`} target="_blank" rel="noopener noreferrer" className="icon-link">
                        <FaTwitter />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
